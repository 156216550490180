import React, { useState } from 'react';
import { above, below } from 'src/components/global/mediaqueries';
import Button from 'src/components/Button';
import Error from 'src/components/general/Error';
import Heading from 'src/components/Heading';
import Input from 'src/components/form/Input';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import theme from 'src/styles/theme';
import useAuth from 'src/hooks/useAuth';

const LoginWrapper = styled('div')`
    padding: 40px 12px 20px 12px;
    background-color: ${theme.color.white};

    ${above.tablet_s}{
        padding: 24px;
    }
`;

const LoginHeader = styled(Heading)`
    font-size: 24px;
    margin-bottom: 16px;
    line-height: 1;
    position: relative;
`;

const LoginForm = styled('form')`
    margin: 24px 0 0 0;
`;

const LoginLink = styled('div')`
    color: ${theme.color.black};
    font-size: 16px;
    text-decoration: underline;
    cursor: pointer;
`;

const StyledInput = styled(Input)`
    width: 100%;
`;

const LoginFormFieldWrapper = styled('div')`
    ${below.tablet_s} {
        & + & {
            ${theme.spacing.mobile._16('margin-top')};
        }

        & > div {
            & + div {
                ${theme.spacing.mobile._16('margin-top')};
            }
        }
    }

    ${above.tablet_s} {
        display: flex;
        width: 100%;
        & + & {
            ${theme.spacing.tablet._24('margin-top')};
            ${theme.spacing.desktop._24('margin-top')};
        }

        & > div {
            flex: 1;
            + div {
                ${theme.spacing.tablet._16('padding-left')};
            }
        }
    }
`;

const ErrorText = styled('p')`
    font-size: 12px;
    color: ${theme.color.berries};
    padding-left: 20px;
    padding-top: 5px;
`;

const InputFieldWrapper = styled('div')`
    margin-bottom: 24px;
`;

const LoginSection = ({activeComponentProps}) => {
    const setView = activeComponentProps;
    const { login } = useAuth();
    const [errors, setErrors] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const isFormValid = !!password && !!email;

    const handleLoginSubmit = async () => {
        try {
            await login({ email, password });
            document.location.reload();
        } catch (e) {
            setErrors(e.response.data.errors);
        }
    };

    return (
        <LoginWrapper>
            <LoginHeader as="h2">Sign in</LoginHeader>
            <div>
                <LoginLink onClick={() => setView('AccountRegister')}>Or create an account here</LoginLink>
            </div>
            <LoginForm onSubmit={handleLoginSubmit}>
                <LoginFormFieldWrapper>
                    <InputFieldWrapper>
                        <StyledInput
                            required
                            invalid={errors['email']}
                            label="Email"
                            name="email"
                            onChange={e => {
                                setEmail(e.target.value);
                            }}
                        />
                        {!!errors.email && <ErrorText>{errors.email[0]}</ErrorText>}
                    </InputFieldWrapper>
                    <InputFieldWrapper>
                        <StyledInput
                            required
                            invalid={errors['password']}
                            label="Password"
                            name="password"
                            type="password"
                            onChange={e => {
                                setPassword(e.target.value);
                            }}
                        />
                        {!!errors.password && <ErrorText>{errors.password[0]}</ErrorText>}
                    </InputFieldWrapper>
                </LoginFormFieldWrapper>
                <div style={{textAlign: 'left'}}>
                    <Button small rounded disabled={!isFormValid} onClick={handleLoginSubmit}>Log In</Button>
                </div>
                <div className="form-control">
                    <Error error={errors['password'] ? errors['password'] : null} />
                </div>
            </LoginForm>
        </LoginWrapper>
    );
};

LoginSection.propTypes = {
    activeComponentProps: PropTypes.func.isRequired,
};

export default LoginSection;
